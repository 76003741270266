<template>
    <div v-if="edtCartouche.version==1">
        <collapse-panel class="cartouche" :title="$t('note-editor.ballet.execution')" :visible="executionPanelVisible" @expand="executionPanelVisible=true" @collapse="executionPanelVisible=false">
            <b-container>
                <score-row class="even" v-model="edtCartouche.controle" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.control")}}
                </score-row>
                <score-row class="odd" v-model="edtCartouche.utilisationFenetre" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.window") }}
                </score-row>
                <score-row class="even" v-model="edtCartouche.difficulteTechnique" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.technical_difficulties")}}
                </score-row>
                <score-row class="odd" v-model="edtCartouche.rythmeExec" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.rhythm") }}
                </score-row>
                <score-row class="even" v-model="edtCartouche.timing" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.timing")}}
                </score-row>
                <score-row class="odd" v-model="edtCartouche.espacement" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.spacing") }}
                </score-row>
            </b-container>
        </collapse-panel>
        <slot name="between-execution-and-choregraphy"></slot>
        <collapse-panel class="cartouche" :title="$t('note-editor.ballet.choreo')" :visible="choregraphyPanelVisible" @expand="choregraphyPanelVisible=true" @collapse="choregraphyPanelVisible=false;">
            <b-container>
                <score-row class="even" v-model="edtCartouche.continuite" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.continuity") }}
                </score-row>
                <score-row class="odd" v-model="edtCartouche.creativite" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.creativity") }}
                </score-row >
                <score-row class="even" v-model="edtCartouche.complexite" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.complexity") }}
                </score-row >
                <score-row  class="odd" v-model="edtCartouche.interpretation" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.interpretation") }}
                </score-row >
                <score-row  class="even" v-model="edtCartouche.rythmeChoreo" :readonly="readonly" :options="options" @change="emit_change">
                    {{ $t("note-editor.criterias.rhythm")}}
                </score-row >
            </b-container>
        </collapse-panel>
        <slot name="between-choregraphy-and-comments"></slot>
         <collapse-panel :title="$t('note-editor.comments')" :visible="commentsPanelVisible" @expand="commentsPanelVisible=true" @collapse="commentsPanelVisible=false">
            <b-textarea v-model="edtCartouche.comments"></b-textarea>
        </collapse-panel>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import CollapsePanel from '@/components/Panels/CollapsePanel.vue';
import ScoreRow from './ScoreDetailDecisionHelperRow.vue';

export default {
    components:{ CollapsePanel, ScoreRow },
    props: {
        value: { type: Object, required: true},
        readonly: { type: Boolean, default: false},
    },
    data(){
        return {
            edtCartouche: this.value,
            options: [
                { text: this.$t("note-editor.criteria-level.low"), value: "low"},
                { text: this.$t("note-editor.criteria-level.medium"), value: "medium"},
                { text: this.$t("note-editor.criteria-level.good"), value: "good"},
            ] 
        };
    },
    computed:{
        ...mapGetters('config', [ 'cfgBalletNoteEditor_executionPaneVisible', 'cfgBalletNoteEditor_choregraphyPaneVisible', 'cfgBalletNoteEditor_commentsPaneVisible']),

        executionPanelVisible:{
            get: function(){ return this.cfgBalletNoteEditor_executionPaneVisible; },
            set: function(newVal) { this.setCfgBalletNoteEditor_executionPaneVisible(newVal); }
        },
        choregraphyPanelVisible:{
            get: function(){ return this.cfgBalletNoteEditor_choregraphyPaneVisible; },
            set: function(newVal) { this.setCfgBalletNoteEditor_choregraphyPaneVisible(newVal); }
        },
        commentsPanelVisible:{
            get: function(){ return this.cfgBalletNoteEditor_commentsPaneVisible; },
            set: function(newVal) { this.setCfgBalletNoteEditor_commentsPaneVisible(newVal); }
        },
    },
    methods:{
        ...mapMutations('config', [ 'setCfgBalletNoteEditor_executionPaneVisible', 'setCfgBalletNoteEditor_choregraphyPaneVisible', 'setCfgBalletNoteEditor_commentsPaneVisible']),
     
        emit_change(){
            this.$emit('change');
        },
    },
}
</script>

<style >
.cartouche .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
}
/*
.cartouche .container .even{
    background-color: rgb(249 238 203);
}
.cartouche .container .odd{
    background-color: #ffe186;
}*/
.odd{
    background-color: lightgray !important;
}
.even{
    background-color: whitesmoke !important;
}


</style>
